import React from 'react';
import { InfoCardsUI } from '../components/InfoCardsUI';
import { CommonHeader } from '../components/CommonHeader';
import styled from 'styled-components';
import icon_un_sdg from '../assets/presentation/icons/un_sdg_free.png';
import icon_un_fao from '../assets/presentation/icons/un_fao.png';
import { Helmet } from 'react-helmet';

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        text-transform: uppercase;    
        font-size: 2.5em;
    }
    
    .heading {
        font-family: 'Optima';
        text-align: center;
        padding-top: 20px;
        font-size: 2.5em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family: 'Optima', Open Sans;
        font-style: bold;
        color: #555555;
        padding-top: 0.5em;
        padding-right: 1em;
        padding-bottom: 1.2em;
        padding-left: 1em;
        line-height:1.6;
        position: relative;
    }
    /* Your current styles */
    .list {
        font-size: 1.5em;
        font-family: 'Optima', Open Sans;
        list-style-type: none;
        padding: 0;
        margin-right: 50px; /* Add space between lists on desktop */
        // width: 90%; /* Set a specific width, you can adjust this value */
        text-align: left; /* Ensures consistent alignment */
    }

/* Styles for the container on larger screens */
.list-container {
    display: flex;
    justify-content: center;
}

/* Media query for mobile and smaller screens */
@media (max-width: 768px) {
    .list-container {
        flex-direction: column; /* Stack lists vertically */
        align-items: center; /* Center lists on mobile */
    }

    .list {
        margin-right: 0; /* Remove space between lists on mobile */
        width: 95%;
    }
    .heading {
        font-size: 1.8em;
    }
}

    @media only screen and (min-width: 768px) {
        .ludwig {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .mycon {
        display: flex;
        flex-wrap: wrap;
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }

`;

export const About = () => (
    <React.Fragment>
    <Helmet>
        <title>M-Power Nature, Blossom Life | About Us</title>
        <meta name="description" content="Discover the story behind MahaMeru. What keeps us interested in the nurturing of soil and how we got into agrotech" />
    </Helmet>
    <CommonHeader 
        // title="About"
        title="Mahameru Innovations: Where Solutions Thrive!"
        // para="Mahameru Innovations: Where Solutions Thrive!"
    />
        <Styles>
        <p className='ludwig'>
            At Mahameru, we are a diverse group of individuals hailing from various walks of life – Engineers, CAs, Teachers, Farmers, Scientists… 
            united by an extraordinary vision: to rid our food chain of harmful chemicals & create a new standard of food focused on nutrition”. 
            Our collective efforts are dedicated to a singular purpose that stands to benefit all life forms in nature. <br />
            We're not just about products – but about empowering you with solutions that resonate with nature's wisdom. Our ethos is rooted in
            sustainability and cost-effectiveness, with a commitment to integrating solutions seamlessly into their native environments.
            From substrate structure design to ingenious soil & water management, from outsmarting pests to nurturing vibrant vegetation – 
            we've devoted years to R&D to craft solutions that span across agriculture, animal farms, milling, and aquatic environments. 
            They are easy to adopt & solve multiple challenges simultaneously. Our secret? A harmonious blend of Engineering, Biology and 
            Agriculture.
        </p>
        <p className='heading' style={{textAlign: 'center', fontWeight: 'bold'}}>
            Our Market Segments are:
        </p>
        <div className="list-container">
            <ul className="list">
                <li >🌾 Agricultural Farms</li>
                <li>🌿 Aquaponics & Hydroponics</li>
                <li>💧 Filtration Systems</li>
                <li>🐄 Animal Farms</li>
            </ul>
            <ul className="list">
                <li>🌳 Orchards & Trees</li>
                <li>🐟 Indoor Fish Farms</li>
                <li>🐖 Feed Mill & Grain Storage</li>
                <li>⛳️ Sports Field & Golf Courses</li>
            </ul>
        </div>


        <p className='ludwig' style={{textAlign: 'center'}}>
            🌍 Together, we're nurturing nature's potential and creating a world where innovation and ecology thrive hand in hand. 🌍
        </p>

        <h2 className="heading" style={{fontWeight: 'bold', paddingBottom: '20px'}}>
            The Mahameru Impact
        </h2><hr />
        <ul>
            <li className='ludwig'>All the solutions are <b>pure in nature</b> without any chemical additives at any processing stage.</li>
            <li className='ludwig'>They are <b>chemically inert</b> and are not harmful to the Environment, Humans & Animals.</li>
            <li className='ludwig'>Our solutions align with the <b>United Nations' Sustainable Development Goals (SDGs)</b> such as SDG 6: Clean Water and 
            Sanitation; SDG 11: Sustainable Cities and Communities; SDG 13: Climate Action; SDG 15: Life on Land which focus on 
            creating environmentally stable and sustainable communities for an enhanced quality of living.</li>
            <li className='ludwig'>We also support <b>Climate-Smart Agriculture (CSA)</b> developed in 2009 by FAO – Food and Agricultural 
            Organisation of the United Nations – as a unified approach to address climate challenges in relation to agriculture and food security.</li>
            <li className='ludwig'>All of this leads to a significant reduction in the <b>Carbon Footprint impact</b> on the planet.</li>
            <li className='ludwig'>Our support is not limited to supplying the products, we work on problems on the ground and leverage a wide 
            base of <b>research and knowledge</b> from multiple fields of Engineering, Agriculture, Biology and find the right approach as per local 
            conditions to get results.</li>
        </ul>
        <div className='list-container'>
            <ul className='list'>
                <li>🌱 Upto 70% Fertiliser & Water saving</li>
                <li>🐝 100% Bee Friendly</li>
                <li>🌍 Upto 40% Carbon Footprint Reduction</li>
            </ul>
            <ul className='list'>
                <li>🤝 Lifetime Support</li>
                <li>🌞 UV Protection</li>
                <li>🌵 Enhanced Drought Tolerance</li>
            </ul>
        </div>

        <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
            <div className='mybox'>
                <img src={icon_un_fao}
                    width={250}
                    height={240}
                />
            </div>
            <div className='mybox'>
                <img src={icon_un_sdg}
                    width={window.innerWidth <= 768 ? "100%" : 420}
                    height={240}
                />
            </div>
        </div>

        </Styles>
    {/* <InfoCardsUI /> */}
    
    </React.Fragment>
)
