import React from 'react';
import { Layout } from '../components/Layout';
//import { ContactHeader } from '../components/ContactHeader';
import { CommonHeader } from '../components/CommonHeader';
import BorderWrapper from 'react-border-wrapper';
import tLogo from '../assets/telephoneLogo.png';
import eLogo from '../assets/emailLogo.png';
import gmap from '../assets/mapLocation.JPG';
//import './Contact.css'
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Styles = styled.div`

.container{
	display:flex;
	flex-wrap:wrap;
    justify-content: center;
}
.box{
	width:100%;
	margin:5px;
    
}
@media only screen and ( max-width:768px ){
	
	.box{
		width:100%;
	}

    .bgcolor {
        background-color: rgb(255,247,228);
        margin: 0px;
        padding: 0px;
    }
}

.headerstyle {
        font-family: Neutraface Display,script;
        padding-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    .subheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 2em;
    }


    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:'Berkshire Swash', cursive;
        text-align: center;
        line-height:1.6;
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
    }



`;

export const Contact = () => (
    <React.Fragment>
        <Helmet>
            <title>Contact us at Rhiza</title>
            <meta name="description" content="We have dedicated teams for all your queries regarding agriculture and animal care. 
            We M-Power you to take the right step towards sustainable agricultural solutions" />
        </Helmet>
        <div>
        <CommonHeader 
            title="Get In Touch"
            para="We'd love to hear from you. Here's how you can reach us."
        />
        </div>
        <Styles>
            <Layout>
                {/* <h1>Agricultural Team</h1>
                <h1>Software Team</h1>
                <h1>Sales</h1>
                <h1>Support Staff</h1> */}
                <div className="bgcolor">
                <section className="container">
                    <div class="box">
                    <h2 className="subheaderstyle">General Enquiries</h2>
                        <p style={{textAlign: "center"}}><img src={tLogo} alt="Telephone Logo" width="20px" height="20px"/> +919810120846</p>
                        <p style={{textAlign: "center"}}><img src={eLogo} alt="Email Logo" width="20px" height="20px" /> info@rhizaearth.com</p>
                    </div>
                    
                </section>
                </div>
                {/*<div style={{marginTop: '20px'}}>
                <Table id="actual-table" >
                <colgroup>
                    <col className="forty" />
                    <col className="sixty" />
                </colgroup>
                <tr>
                    <td>
                    <h2>Contact Info:</h2>
                    <p><img src={tLogo} alt="Telephone Logo" width="20px" height="20px"/> +97125628807</p>
                    <p><img src={eLogo} alt="Email Logo" width="20px" height="20px" /> info@umalime.com</p>
                    <br />
                    <br />
                    <h2>Corporate Office:</h2>
                    <p><b>Uma Lime Industries LLC</b><br />
                    Industrial Area, ICAD III<br />
                    PO Box: 927981 <br />
                    Mussafah<br />
                    Abu Dhabi                    
                    </p>
                    </td>
                    <td>
                    <a href="https://goo.gl/maps/gazbBSs9JAnCaaFu9" rel="noopener noreferrer" target="_blank">
                    <img style={{width: '700px'}} src={gmap} alt="Map Location" />
                    </a>
                    </td>
                    </tr>
                    </Table>
                </div>*/}
                </Layout>
        </Styles>
    </React.Fragment>
)