import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Contact } from './Pages/Contact';
import { NoMatch } from './Pages/NoMatch';
import { Agriculture } from './Pages/Agriculture';
import Invoice from './Pages/Invoice';
import { Mineral } from './Pages/Mineral';
import { Career } from './Pages/Career';
import { Software } from './Pages/Software';
import { Animal } from './Pages/Animal.js';
import { Mbiome } from './Pages/Agro_products/mbiome.js';
import { Events } from './Pages/Events';
import { Catalog } from './Pages/Catalog';
import NavigationBarNew from './components/NavigationBarNew';
import { FooterComponent } from './components/FooterComponent';

import mterra from './assets/animal/mterra.pdf';
import pest_target from './assets/m-defend/pest_target.pdf';

import maquacare from './assets/pdf/m-aquacare.pdf';
import mdetox from './assets/pdf/m-detox.pdf';
import mperform from './assets/pdf/m-perform.pdf';
import mdefend from './assets/pdf/m-defend.pdf';
import mgeoshade from './assets/pdf/m-geoshade.pdf';
import mgeoshield from './assets/pdf/m-geoshield.pdf';
import mhive from './assets/pdf/m-hive.pdf';
import mvermipro from './assets/pdf/m-vermipro.pdf';
import mterraboost from './assets/pdf/m-terraboost.pdf';
import mbrochure from './assets/pdf/m-brochure.pdf';

import { PDFReader, MobilePDFReader } from 'react-read-pdf';
//TODO: Add Kam profile
//TODO: update website with ECA information
//TODO: update software page with new events software made
//TODO: add aimal feed and animal feed production section
//TODO: Review if subdomains per category are needed to be added like agriculture.maha-meru.com
//TODO: Check if we need to remove the minerals information displayed in the minerals page, maybe we can write it as per use.
//TODO: New photos for each member
//TODO: Add vermicompost and vermiwash etc products


function App() {
  return (
    <div className="page-container">
    <div className="content-wrap">
    <NavigationBarNew />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/agriculture" component={Agriculture} />
          <Route path="/animal" component={Animal} />
          <Route path="/career" component={Career} />
          <Route path="/contact" component={Contact} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
      </div>
      <FooterComponent />
      </div>
  );
}

export default App;