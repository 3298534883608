import React from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
import styled from 'styled-components';
import mahameru2 from '../assets/mahameru2.jpg';
import mahameru3 from '../assets/mahameru3.jpg';
import mahameru1_1 from '../assets/mahameru1_1.jpg';
import { Helmet } from 'react-helmet';
import icon_un_sdg from '../assets/presentation/icons/un_sdg_free.png';
import icon_un_fao from '../assets/presentation/icons/un_fao.png';

//TODO: make the colored section with no margins on the side, let the color be on the sides

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: 'Optima', Neutraface Display, script;
        padding-top: 20px;
        text-transform: uppercase;
        font-size: 2.5em;    
    }
    .subheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 2.2em;
    }
    .heading {
        font-family: 'Optima';
        text-align: center;
        padding-top: 20px;
        font-size: 2.5em;
    }
    .introstyle {
        font-size: 1.5em;
        width: 100%;
        font-family: 'Optima', Open Sans;
        font-style: bold;
        color: #555555;
        text-align: center;
        font-weight: bold;
    }
    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family: 'Optima', Open Sans;
        font-style: bold;
        color: #555555;
        padding-top: 0.5em;
        padding-right: 1em;
        padding-bottom: 1.2em;
        padding-left: 1em;
        line-height:1.6;
        position: relative;
    }
    .list {
        font-size: 1.5em;
        font-family: 'Optima', Open Sans;
        list-style-type: none;
        padding: 0;
        margin-right: 50px; /* Add space between lists on desktop */
        // width: 90%; /* Set a specific width, you can adjust this value */
        text-align: left; /* Ensures consistent alignment */
    }

/* Styles for the container on larger screens */
.list-container {
    display: flex;
    justify-content: center;
}

/* Media query for mobile and smaller screens */
@media (max-width: 768px) {
    .list-container {
        flex-direction: column; /* Stack lists vertically */
        align-items: center; /* Center lists on mobile */
    }

    .list {
        margin-right: 0; /* Remove space between lists on mobile */
        width: 95%;
    }
    .heading {
        font-size: 1.8em;
    }
}

    @media only screen and (min-width: 768px) {
        .ludwig {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .mycon {
        display: flex;
        flex-wrap: wrap;
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }


`;

export const Home = () => (
    <React.Fragment>
    <Helmet>
        <title>Rhiza – Your source for Agrotech | Home</title>
        <meta name="description" content="We believe in a natural sustainable approach to nurturing the soil. 
        Our range of products are M-Hive, M-TerraBoost, M-TerraDry, M-Geoshade, M-Geoshield, M-Defend, M-VermiPro, M-Detox, M-Perform, M-Aquacare" />
    </Helmet>
    <Styles>
        <CommonHeader 
            title='"Nurture Nature, Flourish Together"'
        />
        <Layout>
            <div style={{marginTop: '20px'}}>
                <p className="introstyle">
                We envision a future where organic, wholesome food is a household staple, nurturing WHO’s 'One Health' concept. 
                Our mission is to revolutionize the very core of the food cycle with inventive and impactful changes. 
                We strive for everything to be Organic, Sustainable, and in Sync with the environment, nurturing both the earth and the spirit.​
                </p>
            </div>
            {/* change especially in the field of agriculture */}
            </Layout>
            <p className='ludwig'>
            "Rhiza: Cultivating Innovation for a Sustainable Future"​
At Rhiza, we bring together a multidisciplinary team with a shared goal: to eliminate harmful chemicals from our food and set a new nutritional 
standard. <br />The Founders: Mr Promod Gupta & Babita Garg have been connected to farming since their childhood & have always been enthusiastic 
supporters of Organic & Natural farming practices. <br />Our mission is to offer sustainable, cost-effective solutions that blend seamlessly with the 
<br />
<p style={{textAlign: 'center', marginTop: '20px'}}>
    🌍 United in purpose, we're fostering innovation alongside ecology for a healthier world. 🌍​ 
</p>
        </p>
        <p className='heading' style={{textAlign: 'center', fontWeight: 'bold'}}>
            Our Market Segments are:
        </p>
        <div className="list-container">
            <ul className="list">
                <li >🌾 Agricultural Farms</li>
                <li>🌿 Aquaponics & Hydroponics</li>
                <li>💧 Filtration Systems</li>
                <li>🐄 Animal Farms</li>
            </ul>
            <ul className="list">
                <li>🌳 Orchards & Trees</li>
                <li>🐟 Indoor Fish Farms</li>
                <li>🐖 Feed Mill & Grain Storage</li>
                <li>⛳️ Sports Field & Golf Courses</li>
            </ul>
        </div>

        <p className='ludwig' style={{textAlign: 'center'}}>
            🌍 Together, we're nurturing nature's potential and creating a world where innovation and ecology thrive hand in hand. 🌍
        </p>

        <h2 className="heading" style={{fontWeight: 'bold', paddingBottom: '20px'}}>
            Our Impact
        </h2><hr />
        <ul>
            <li className='ludwig'>Our products are natural, free from chemical additives, and safe for all life forms.</li>
            <li className='ludwig'>We champion the UN's Sustainable Development Goals to foster sustainable, thriving communities.</li>
            <li className='ludwig'>Endorsing Climate-Smart Agriculture, we address global climate issues through smart farming.</li>
            <li className='ludwig'>Our strategies significantly lower the carbon footprint, contributing to a greener planet.</li>
            <li className='ludwig'>Beyond product supply, we actively engage in problem-solving through interdisciplinary research.</li>
        </ul>
        <div className='list-container'>
            <ul className='list'>
                <li>🌱 Upto 70% Fertiliser & Water saving</li>
                <li>🐝 100% Bee Friendly</li>
                <li>🌍 Upto 40% Carbon Footprint Reduction</li>
            </ul>
            <ul className='list'>
                <li>🤝 Lifetime Support</li>
                <li>🌞 UV Protection</li>
                <li>🌵 Enhanced Drought Tolerance</li>
            </ul>
        </div>

        <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
            <div className='mybox'>
                <img src={icon_un_fao}
                    width={250}
                    height={240}
                />
            </div>
            <div className='mybox'>
                <img src={icon_un_sdg}
                    width={window.innerWidth <= 768 ? "100%" : 420}
                    height={240}
                />
            </div>
        </div>

            
        </Styles>
    </React.Fragment>
)

