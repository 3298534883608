import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Styles = styled.div`
.social-icon {
  color: rgb(232, 204, 177); /* Initial color */
  font-size: 24px;
  margin: 0 10px;
  transition: transform 0.3s ease, color 0.3s ease; /* Animation transition */
  text-decoration: none; /* Remove underline from links */
}

.social-icon:hover {
  color: #0077b6; /* Change color on hover */
  transform: scale(1.2); /* Scale icon on hover */
}

`;
export const FooterComponent = () => (


    <MDBFooter color="stylish-color-dark" className="page-footer font-small pt-4 mt-4">
          <MDBContainer fluid className="text-center text-md-left">
            <MDBRow>
              <MDBCol md="6">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Rhiza Management LLP
                </h5>
                
                <p>
                <br />
                  Get In Touch:<br />
                  Phone: <b>+919810120846</b> <br />
                  Email: <b>info@rhizaearth.com</b>
                </p>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              <MDBCol md="2">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Discover
                </h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/agriculture">Agriculture</a>
                  </li>
                  <li>
                    <a href="/animal">Animal</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              <MDBCol md="2">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Find Out More
                </h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/career">Careers</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              
            </MDBRow>
          </MDBContainer>
          
          <div className="footer-copyright text-center py-3">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Rhiza Management LLP
            </MDBContainer>
          </div>
        </MDBFooter>
        
    )
    